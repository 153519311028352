import React, { useEffect, useState } from 'react';
import SeoMetaTags from '../Seo';

import { Link } from 'react-router-dom'
import CourseLayout from '../Layouts/CourseLayout';
import image from '../assets/images/courses/front-end-101.png'
import imageII from '../assets/images/courses/front-end-101-2.png'
import content from '../Courses/frontend101.json'

const FrontEnd101 = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    // JSON dosyasını kullanarak veriyi set edin
    setData(content);
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }


  return(
    <CourseLayout>
      <div>
      <SeoMetaTags
        title="Front-End 101"
        description="SEOEM Akademi ile front-end yolculuğuna hoş geldiniz. Bizimle sıfırdan uzmanlığa ulaşacaksınız."
        url="https://academy.seoem.co/frontend101"
        image="../assets/images/courses/front-end-101.jpg"
      />
      <h1 className='text-center text-seoem-100 text-3xl font-bold my-2'>{data.title} <span className='text-sm font-thin'></span></h1>
      <div className='flex max-xl:flex-col max-xl:items-center w-2/3 justify-center mx-auto my-4'>

        <div className='relative'>
          <img className='max-w-[450px] max-lg:w-full rounded-md' src={image} alt='frontend101 görsel'/>
          <span className='bg-red-700 text-white text-center p-1 rounded-md absolute top-2 left-2'>{data.datewtext}</span>
        </div>
        <div className='flex flex-col text-justify max-xl:items-center mx-4'>

          <p className='text-black text-lg font-thin opacity-75'>{data.description}</p>
          <h2 className='text-center text-seoem-100 text-2xl font-bold mt-4'>Kazanımlar</h2>
          <ul className='text-black text-lg font-thin opacity-75 list-disc pl-2 mt-4'>
            {data.awards.map((award, index) => (
            <li key={index}>{award}</li>
            ))} 
          </ul>
          <ul className='mt-4 ml-10'>
          <Link target='_blank' className='btn btn-light' to={data.link}>Ön Kayıt</Link>
          </ul>
        </div>
      </div>
      <div className='image-content-box'>
        <img src={imageII} alt="Eğitim içeriği" className='image-content'/>
      </div>
    </div>
    </CourseLayout>
  )
}

export default FrontEnd101;