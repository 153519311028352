import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import VeriBilimi101 from "./Courses/VeriBilimi101"
import FrontEnd101 from "./Courses/FrontEnd101";

function App() {
  return (
    <div className="bg-[#f0f8ff]">
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/courses" element={<Home/>}/>
        <Route path="/veribilimi101" element={<VeriBilimi101/>}/>
        <Route path="/frontend101" element={<FrontEnd101/>}/>
      </Routes>
    </div>
  );
}

export default App;
